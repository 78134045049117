<template>
    <BT-Blade-Items
        addBladeName="client-invoice"
        bladeName="client-invoices"
        :bladesData="bladesData"
        :canAdd="false"
        :headers="[
            { text: 'INV#', value: 'invoiceNumber', subtitle: 2, prefix: 'INV#' },
            { text: 'Due Date', value: 'dueOn', textFilter: 'toShortDate', subtitle: 1 }, 
            { text: 'Customer', value: 'buyer.companyName', title: 1 },
            { text: 'Amount', value: 'amountTotal', textFilter: 'toCurrency' },
            { text: 'Status', value: 'isPaid', bool: true }]"
        hideActions
        navigation="client-invoices"
        title="Client Invoices"
        useServerPagination>
        <template v-slot:settings>
            <v-divider />
            <v-subheader>Actions <v-spacer />
                <v-btn small icon :to="{ name: 'courier-invoicing-actions' }" title="View Past Actions">
                    <v-icon small>mdi-history</v-icon>
                </v-btn>
            </v-subheader>
            <BT-Entity
                v-if="$canView('courier-invoicing-settings')"
                ignoreID
                inline
                navigation="courier-invoicing-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Invoicing</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Menu-Automation 
                                    v-model="item.invoiceAutomation"
                                    isLargeRange
                                    @change="save" />
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.invoiceGenerationGuide)"
                                    v-model="item.invoiceGenerationTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon
                                    v-model="item.invoiceGenerationGuide"
                                    @change="save" />
                                <v-btn icon small @click="createInvoices" title="Now">
                                    <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Email Unpaid Invoice Reminders</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Menu-Automation 
                                    v-model="item.invoiceReminderAutomation" 
                                    isLargeRange
                                    @change="save" />
                                <v-btn icon small @click="emailInvoiceReminders" title="Now">
                                    <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Push To 3rd Party</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Menu-Select
                                    icon="mdi-account-network"
                                    item-text="id"
                                    item-value="id"
                                    navigation="external-party-options"
                                    v-model="item.invoicePushExternalParty"
                                    @change="save" />
                                <BT-Menu-Automation 
                                    v-model="item.invoicePushAutomation"
                                    dailyOnly
                                    @change="save" />
                                <v-btn :disabled="item.invoicePushExternalParty == null" icon small @click="pushToExternalParty" title="Now">
                                    <v-icon small class="mx-1">mdi-motion-play-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </template>
             </BT-Entity>
            <v-divider />
            <v-subheader>Settings</v-subheader>
            <BT-Entity
                v-if="$canView('company-settings')"
                ignoreID
                inline
                navigation="company-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Receive Invoice Updates From Xero</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch v-model="item.useCourierXeroWebhooks" @change="save" color="success" />
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
            <BT-Entity
                v-if="$canView('courier-invoicing-settings')"
                ignoreID
                inline
                navigation="courier-invoicing-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Price Tier For Self</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <BT-Menu-Select
                                icon="mdi-currency-usd"
                                itemText="priceTierName"
                                itemValue="id"
                                navigation="courier-price-tiers"
                                v-model="item.priceTierID"
                                @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Charge GST</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch v-model="item.chargeGST" @change="save" color="success" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Invoice Number Prefix</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog>
                                <span :class="item.invoicePrefix == null ? 'grey--text lighten-2' : ''">{{ item.invoicePrefix || '(prefix)' }}</span>
                                <template v-slot:input>
                                    <v-text-field
                                        v-model.number="item.invoicePrefix"
                                        @change="save"
                                        label="Prefix"
                                        placeholder="Prefix"
                                        prepend-icon="#"
                                        singleLine
                                        hideDetails
                                        type="number" />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Last Invoice Number</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog>
                                <span>#{{ item.invoicePrefix }}{{ item.lastInvoiceNumber }}</span>
                                <template v-slot:input>
                                    <v-text-field
                                        v-model.number="item.lastInvoiceNumber"
                                        @change="save"
                                        label="Last Invoice Number"
                                        :prepend-icon="'#' + (item.invoicePrefix || '')"
                                        singleLine
                                        hideDetails />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Email Invoice Notifications To</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-edit-dialog>
                                <v-btn small icon :title="item.emailAddressesAsCourier">
                                    <v-icon small :class="item.emailAddressesAsCourier == null || item.emailAddressesAsCourier.length == 0 ? '' : 'success--text'">mdi-email</v-icon>
                                </v-btn>
                                <template v-slot:input>
                                    <BT-Tags 
                                        v-model="item.emailAddressesAsCourier"
                                        @change="save" />
                                </template>
                            </v-edit-dialog>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: "Client-Invoices",
    components: {
        BTMenuSelect: () => import('~components/BT-Menu-Select.vue'),
        BTMenuAutomation: () => import('~components/BT-Menu-Automation.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue'),
    },
    props: {
        bladesData: null
    },
    methods: {
        createInvoices() {
            console.log('creating invoices');
        },
        emailInvoiceReminders() {
            console.log('email reminders');
        },
        pushToExternalParty() {
            console.log('pusshing external');
        }
    }
}
</script>